<template>
  <div>
    <!-- Modal Messages-->
    <b-modal
      id="modal-messages"
      v-model="modalMessage"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Messages"
    >
      <b-form>
        <div>
          <div class="messages">
            <ul
              v-if="messagesList.length > 0"
              class="list-group"
              style="height:300pxwidth:100%overflow-y:scroll"
            >
              <input
                id="PurchaseOrderId"
                ref="invoiceMessage"
                v-model="PurchaseOrderId"
                type="hidden"
                name="PurchaseOrderId"
              />
              <li
                v-for="item in messagesList"
                :key="item"
                class="list-group-item"
              >
                <div
                  v-if="userId.toString() == item.user.user_id.toString()"
                  class="float-right"
                >
                  <h5>Me</h5>
                  <span style="font-size:9px">{{
                    dateSimple(
                      item.purchase_order_chat_created_time,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}</span>
                  <p>{{ item.purchase_order_chat_text }}</p>
                </div>
                <div
                  v-if="userId.toString() != item.user.user_id.toString()"
                  class="float-left"
                >
                  <h5>{{ item.user.user_fullname }}</h5>
                  <span style="font-size:9px">{{
                    dateSimple(
                      item.purchase_order_chat_created_time,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}</span>
                  <p>{{ item.purchase_order_chat_text }}</p>
                </div>
              </li>
            </ul>
            <div v-if="messagesList.length == 0">
              No message
            </div>
            <br />
            <form id="send_message_form" method="post" action="">
              <b-row>
                <b-col cols="11" md="9">
                  <input
                    v-model="messageBox"
                    type="text"
                    class="form-control"
                    placeholder="Write your message"
                  />
                </b-col>
                <b-col cols="11" md="3"
                  ><input
                    type="button"
                    class="btn btn-primary pull-right"
                    value="Send"
                    @click="sendMessage()"
                /></b-col>
              </b-row>
            </form>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button variant="primary" @click="addNew()" v-if="userRole=='purchasing-cabang'">
                <span class="text-nowrap">Add New Receive</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refPurchaseOrderReceivePurchasingTable"
        class="position-relative"
        :items="fetchPurchaseOrdersReceivesPurchasing"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: PO_Number -->
        <template #cell(PO_Number)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.purchase_order ? data.item.purchase_order.purchase_order_number : "-"
            }}</span>
          </div>
        </template>
        <!-- Column: ReceivingNumber -->
        <template #cell(ReceivingNumber)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.purchase_order_purchasing_receive_receiving_number
            }}</span>
          </div>
        </template>
        <!-- Column: TotalReceived -->
        <template #cell(TotalReceived)="data">
          <b-badge pill class="text-capitalize">
            {{ data.item.purchase_order_purchasing_receive_total_received }}
          </b-badge>
        </template>
        <!-- Column: CreatedBy -->
        <template #cell(CreatedBy)="data">
          {{ data.item.user.user_fullname }}
        </template>
        <!-- Column: DateCreated -->
        <template #cell(DateCreated)="data">
          {{
            dateSimple(
              data.item.purchase_order_purchasing_receive_created_time,
              "YYYY-MM-DD HH:mm:ss"
            )
          }}
        </template>
        <!-- Column: Action -->
        <template #cell(Action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="showDetail(data.item)">
              <feather-icon icon="ZoomInIcon" />
              <span class="align-middle ml-50">Detail</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_lab_manager') == true &&
                  data.item.purchase_order_is_confirmed == null &&
                  data.item.purchase_order_is_center == false
              "
              @click="confirmPurchaseOrder(data.item.purchase_order_id)"
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_lab_manager') == true &&
                  data.item.purchase_order_is_center == true &&
                  data.item.purchase_order_is_confirmed_by_laboratory_manager ==
                    null
              "
              @click="
                confirmPurchaseOrderByLabManager(data.item.purchase_order_id)
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_regional_manager') ==
                  true &&
                  data.item.purchase_order_is_center == true &&
                  data.item.purchase_order_is_confirmed_by_laboratory_manager !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_regional_manager ==
                    null
              "
              @click="
                confirmPurchaseOrderByRegionalManager(
                  data.item.purchase_order_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_division_head') ==
                  true &&
                  data.item.purchase_order_is_center == true &&
                  data.item.purchase_order_is_confirmed_by_laboratory_manager !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_regional_manager !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_division_head == null
              "
              @click="
                confirmPurchaseOrderByDivisionHead(data.item.purchase_order_id)
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_fa_coal_manager') ==
                  true &&
                  data.item.purchase_order_is_center == true &&
                  data.item.purchase_order_is_confirmed_by_laboratory_manager !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_regional_manager !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_division_head !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_fa_coal_manager ==
                    null
              "
              @click="
                confirmPurchaseOrderByFACoalManager(data.item.purchase_order_id)
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_operation_director') ==
                  true &&
                  data.item.purchase_order_is_center == true &&
                  data.item.purchase_order_is_confirmed_by_laboratory_manager !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_regional_manager !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_division_head !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_fa_coal_manager !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_operation_director ==
                    null
              "
              @click="
                confirmPurchaseOrderByOperationDirector(
                  data.item.purchase_order_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_vice_president') ==
                  true &&
                  data.item.purchase_order_is_center == true &&
                  data.item.purchase_order_is_confirmed_by_laboratory_manager !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_regional_manager !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_division_head !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_fa_coal_manager !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_operation_director !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_vice_president ==
                    null
              "
              @click="
                confirmPurchaseOrderByVicePresident(data.item.purchase_order_id)
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_president_director') ==
                  true &&
                  data.item.purchase_order_is_center == true &&
                  data.item.purchase_order_is_confirmed_by_laboratory_manager !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_regional_manager !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_division_head !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_fa_coal_manager !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_operation_director !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_vice_president !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_president_director ==
                    null
              "
              @click="
                confirmPurchaseOrderByPresidentDirector(
                  data.item.purchase_order_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_procurement_pusat') ==
                  true &&
                  data.item.purchase_order_is_center == true &&
                  data.item.purchase_order_is_confirmed_by_laboratory_manager !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_regional_manager !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_division_head !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_fa_coal_manager !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_operation_director !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_vice_president !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_president_director !=
                    null &&
                  data.item.purchase_order_is_confirmed_by_procurement_pusat ==
                    null
              "
              @click="
                confirmPurchaseOrderByProcurementPusat(
                  data.item.purchase_order_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPurchaseOrder"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue"
import axios from "axios"
import vSelect from "vue-select"
import store from "@/store"
import { getUserData } from "@/auth/utils"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { ref, onUnmounted } from "@vue/composition-api"
import { avatarText } from "@core/utils/filter"
import moment from "moment"
import { VueHorizontalTimeline } from "vue-horizontal-timeline"
import PurchaseOrderReceivePurchasing from "./PreReceivePurchasing"

const userRole = ""
const messageBox = ""
const to = ""
const cc = ""
const reason = ""
const PurchaseOrderId = ""
const messagesList = []
const statusItems = []
const items = []
const userId = getUserData().user_id
const modalDetailShow = false
const modalMessage = false
export default {
  components: {
    VueHorizontalTimeline,
    BListGroup,
    BListGroupItem,
    // PurchaseOrderesListFilters,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      items,
      statusItems,
      PurchaseOrderId,
      userId,
      messagesList,
    }
  },
  mounted() {
    this.userRole = this.$cookies.get("UserRole")
    this.$root.$on("refreshTable", text => {
      console.log(text) // here you need to use the arrow function
      this.$refs.refPurchaseOrderReceivePurchasingTable.refresh()
    })
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = "app-purchase-order"
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
    })

    const isAddNewPurchaseOrderSidebarActive = ref(false)

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ]

    const {
      fetchPurchaseOrdersReceivesPurchasing,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalPurchaseOrder,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPurchaseOrderReceivePurchasingTable,
      refetchData,

      // UI
      resolvePurchaseOrderRoleVariant,
      resolvePurchaseOrderRoleIcon,
      resolvePurchaseOrderStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = PurchaseOrderReceivePurchasing()

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ]

    return {
      to,
      cc,
      reason,
      messageBox,
      modalDetailShow,
      modalMessage,
      // Sidebar
      isAddNewPurchaseOrderSidebarActive,
      userRole,
      fetchPurchaseOrdersReceivesPurchasing,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalPurchaseOrder,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPurchaseOrderReceivePurchasingTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolvePurchaseOrderRoleVariant,
      resolvePurchaseOrderRoleIcon,
      resolvePurchaseOrderStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    }
  },
  methods: {
    deletePurchaseOrder(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(`${process.env.VUE_APP_API_DELETE_MATERIAL_REQUEST}${id}`, {
          headers,
        })
        .then(response => {
          console.log(response)
          if (response.data.success === true) {
            this.$refs.refPurchaseOrderReceivePurchasingTable.refresh()
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Congratulation",
                icon: "CoffeeIcon",
                variant: "success",
                text: "Your data has been deleted!",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Notification",
                icon: "BellIcon",
                variant: "danger",
                text: "Something went wrong",
              },
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    editPurchaseOrder(data) {
      this.$cookies.set("PurchaseOrderEdit", data)
      this.$router.push({ name: "apps-purchase-order-edit" })
    },
    confirmPurchaseOrder(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const body = {
        time: moment(),
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER}/${id}`,
          body,
          { headers },
        )
        .then(async response => {
          if (response.data.success === true) {
            this.$refs.refPurchaseOrderReceivePurchasingTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Confirm Purchase OrderSuccess",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    sendPurchaseOrder(data) {
      this.$cookies.set("PurchaseOrderSend", data)
      this.$router.push({ name: "apps-purchase-order-send" })
    },
    addNew() {
      this.$router.push({
        name: "apps-purchase-order-pre-receive-purchasing-new",
      })
    },
    dateSimple(value, format = "YYYY-MM-DD") {
      let dateRet = ""
      if (value !== null) {
        dateRet = moment(String(value)).format(format)
      } else {
        dateRet = null
      }
      return dateRet
    },
    showDetail(item) {
      localStorage.setItem("PurchaseOrderPurchasingDetail", JSON.stringify(item))
      this.$router.push({
        name: "apps-purchase-order-pre-receive-purchasing-detail",
      })
    },
    hasPermission(permission) {
      const permissions = this.$cookies.get("userPermissions").split(",")
      return permissions.includes(permission) ? true : false
    },
    async receivePurchasingPurchaseOrder(item) {
      localStorage.setItem("poDetail", JSON.stringify(item))
      this.$router.push({ name: "apps-purchase-order-receive-purchasing" })
    },
    async receiveWarehousePurchaseOrder(item) {
      localStorage.setItem("poDetail", JSON.stringify(item))
      this.$router.push({ name: "apps-purchase-order-receive-warehouse" })
    },
    confirmPurchaseOrderByLabManager(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const userData = this.$cookies.get("userData")
      if (userData.user_signature !== null) {
        const body = {
          time: moment(),
        }
        axios
          .post(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER_CENTER}/laboratory_manager/${id}`,
            body,
            { headers },
          )
          .then(async response => {
            if (response.data.success === true) {
              this.$refs.refPurchaseOrderReceivePurchasingTable.refresh()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Confirm Purchase OrderSuccess",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Something went wrong",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              })
            }
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "You have to upload your Signature First!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      }
    },
    confirmPurchaseOrderByRegionalManager(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const body = {
        time: moment(),
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER_CENTER}/regional_manager/${id}`,
          body,
          { headers },
        )
        .then(async response => {
          if (response.data.success === true) {
            this.$refs.refPurchaseOrderReceivePurchasingTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Confirm Purchase OrderSuccess",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    confirmPurchaseOrderByDivisionHead(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const body = {
        time: moment(),
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER_CENTER}/division_head/${id}`,
          body,
          { headers },
        )
        .then(async response => {
          if (response.data.success === true) {
            this.$refs.refPurchaseOrderReceivePurchasingTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Confirm Purchase OrderSuccess",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    confirmPurchaseOrderByFACoalManager(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const body = {
        time: moment(),
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER_CENTER}/fa_coal_manager/${id}`,
          body,
          { headers },
        )
        .then(async response => {
          if (response.data.success === true) {
            this.$refs.refPurchaseOrderReceivePurchasingTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Confirm Purchase OrderSuccess",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    confirmPurchaseOrderByOperationDirector(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const userData = this.$cookies.get("userData")
      if (userData.user_signature !== null) {
        const body = {
          time: moment(),
        }
        axios
          .post(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER_CENTER}/operation_director/${id}`,
            body,
            { headers },
          )
          .then(async response => {
            if (response.data.success === true) {
              this.$refs.refPurchaseOrderReceivePurchasingTable.refresh()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Confirm Purchase OrderSuccess",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Something went wrong",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              })
            }
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "You have to upload your Signature First!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      }
    },
    confirmPurchaseOrderByVicePresident(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const userData = this.$cookies.get("userData")
      if (userData.user_signature !== null) {
        const body = {
          time: moment(),
        }
        axios
          .post(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER_CENTER}/vice_president/${id}`,
            body,
            { headers },
          )
          .then(async response => {
            if (response.data.success === true) {
              this.$refs.refPurchaseOrderReceivePurchasingTable.refresh()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Confirm Purchase OrderSuccess",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Something went wrong",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              })
            }
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "You have to upload your Signature First!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      }
    },
    confirmPurchaseOrderByPresidentDirector(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const userData = this.$cookies.get("userData")
      if (userData.user_signature !== null) {
        const body = {
          time: moment(),
        }
        axios
          .post(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER_CENTER}/president_director/${id}`,
            body,
            { headers },
          )
          .then(async response => {
            if (response.data.success === true) {
              this.$refs.refPurchaseOrderReceivePurchasingTable.refresh()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Confirm Purchase OrderSuccess",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Something went wrong",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              })
            }
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "You have to upload your Signature First!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      }
    },
    confirmPurchaseOrderByProcurementPusat(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const body = {
        time: moment(),
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER_CENTER}/procurement_pusat/${id}`,
          body,
          { headers },
        )
        .then(async response => {
          if (response.data.success === true) {
            this.$refs.refPurchaseOrderReceivePurchasingTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Confirm Purchase OrderSuccess",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    async sendMessage() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const id = this.PurchaseOrderId
      const Message = this.messageBox
      const body = {
        purchase_order_chat_purchase_order_id: id,
        purchase_order_chat_text: Message,
        time: moment(),
      }
      axios
        .post(
          `${process.env.VUE_APP_API_CREATE_MATERIAL_REQUEST_CHATS}`,
          body,
          { headers },
        )
        .then(async response => {
          if (response.data.success === true) {
            this.messageBox = ""
            axios
              .get(
                `${process.env.VUE_APP_API_GET_MATERIAL_REQUEST_CHATS}${id}`,
                { headers },
              )
              .then(response2 => {
                if (response2.data.success === true) {
                  console.log("Fetching Data")
                  if (response2.data.data) {
                    messagesList.splice(0, messagesList.length)
                    response2.data.data.map(value => {
                      messagesList.push(value)
                      return value.invoiceMessage
                    })
                  }
                }
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss"
</style>
